


















































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
@Component({
  components: {
    inPage: () => import('@/components/base/InsPage.vue')
  }
})
export default class photoAlbum extends Vue {
  ImagePath: object = {};
  ImageDate: String = '';
  private CurrentPage: number = 1;
  private pageSize: number = 4;
  private waiting: boolean = true;
  private showimg: boolean = false;
  TotalRecord: number = 0; // 总条目数
  showPageSize: number = 0; // 显示页数
  private SortOrder: string = 'desc';
  private SortName: string = 'CreateDate'

  GetFolders () {
    if (this.isMobile) {
      this.pageSize = 4;
    } else {
      this.pageSize = 6;
    }
    this.$Api.PhotoAlbum.SearchFolders(this.CurrentPage, this.pageSize, this.SortName, this.SortOrder).then(result => {
      console.log(result, 'PhotoAlbum');
      console.log(this.CurrentPage, 'this.CurrentPage');
      console.log(this.pageSize, 'this.pageSize');
      console.log(this.TotalRecord, 'this.TotalRecord');
      this.ImagePath = result.data.Data;
      this.TotalRecord = result.data.TotalRecord;
      this.waiting = false;
      var $this = this;
      // result.data.Data.sort(function (a, b) {
      //   console.log(a, b, 'a.createdate');

      //   return $this.timeToDatetype(a.CreateDate) > $this.timeToDatetype(b.CreateDate) ? -1 : 1;
      // });
      if (this.isMobile) {
        this.showPageSize = 4;
      } else {
        this.showPageSize = 6;
      }
      document.documentElement.scrollTop = 0;
    });
  }
  loadImage () {
    let _this = this;
    setTimeout(function () {
      console.log(4444);
      _this.showimg = true;
    }, 5000);
  }
  timeToDatetype (date) {
    console.log(date, 'datedatedatedate');
    date = date.replace(/-/g, '/');

    var timestamp = new Date(date).getTime();

    // 根据毫秒数构建 Date 对象
    var newDate = new Date(timestamp);
    // 格式化日期
    const dateTime = newDate.toLocaleString();
    return dateTime;
  }
  dateFormat (fmt, date) {
    var o = {
      'M+': date.getMonth() + 1, // 月份
      'd+': date.getDate(), // 日
      'h+': date.getHours(), // 小时
      'm+': date.getMinutes(), // 分
      's+': date.getSeconds(), // 秒
      'q+': Math.floor((date.getMonth() + 3) / 3), // 季度
      'S': date.getMilliseconds() // 毫秒
    };
    if (/(y+)/.test(fmt)) { fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length)); }
    for (var k in o) {
      if (new RegExp('(' + k + ')').test(fmt)) { fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? (o[k]) : (('00' + o[k]).substr(('' + o[k]).length))); }
    }
    return fmt;
  }

  crtTimeFtt (value, row, index) {
    let date = new Date(value.replace(/-/g, '/'));
    return this.dateFormat('yyyy年MM月dd日', date);
  }

  get id () {
    return this.$route.params.id;
  }

  get isMobile () {
    return this.$store.state.isMobile;
  }
  @Watch('isMobile', { deep: true })
  mounted () {
    this.GetFolders();
  }
  @Watch('CurrentPage')
  onPageChange (o, n) {
    this.GetFolders();
  }
}
